import React, { useState, useEffect } from 'react';

export default function StarRating({ averageStars, animatedWidth, animatedDelay }) {
  const MAX_STARS = 5;
  const transitionDuration = 0.1;
  const initialDelay = animatedDelay || 0;

  const [starFillPercentages, setStarFillPercentages] = useState([]);
  const [prevAverageStars, setPrevAverageStars] = useState(averageStars);

  useEffect(() => {
    let timers = [];
    const newPercentages = [];
    const prevPercentages = [];

    for (let i = 1; i <= MAX_STARS; i++) {
      let prevFill = 0;
      if (prevAverageStars >= i) {
        prevFill = 100;
      } else if (prevAverageStars > i - 1) {
        prevFill = (prevAverageStars - (i - 1)) * 100;
      }

      let newFill = 0;
      if (averageStars >= i) {
        newFill = 100;
      } else if (averageStars > i - 1) {
        newFill = (averageStars - (i - 1)) * 100;
      }

      prevPercentages.push(prevFill);
      newPercentages.push(newFill);
    }

    if (animatedWidth) {
      const isIncreasing = averageStars >= prevAverageStars;

      const indicesToAnimate = [];
      for (let i = 0; i < MAX_STARS; i++) {
        if (prevPercentages[i] !== newPercentages[i]) {
          indicesToAnimate.push(i);
        }
      }

      setStarFillPercentages(prevPercentages);

      indicesToAnimate.forEach((index, idx) => {
        const delay = isIncreasing
          ? initialDelay + idx * transitionDuration
          : initialDelay + (indicesToAnimate.length - idx - 1) * transitionDuration;

        const timer = setTimeout(() => {
          setStarFillPercentages((prev) => {
            const updated = [...prev];
            updated[index] = newPercentages[index];
            return updated;
          });
        }, delay * 1000); 

        timers.push(timer);
      });
    } else {
      setStarFillPercentages(newPercentages);
    }

    setPrevAverageStars(averageStars);

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [averageStars, animatedWidth, animatedDelay]);

  const stars = [];

  for (let i = 1; i <= MAX_STARS; i++) {
    const fillPercentage = starFillPercentages[i - 1] || 0;

    const isLastStar =
      (averageStars >= prevAverageStars && i === Math.ceil(averageStars)) ||
      (averageStars < prevAverageStars && i === Math.ceil(prevAverageStars));

    const timingFunction = animatedWidth
      ? isLastStar
        ? 'ease-out'
        : 'linear'
      : 'none';

    const transitionDelay = '0s';

    stars.push(
      <div key={i} className="relative w-5 h-5">
        {/* Empty Star */}
        <img
          src="/images/assets/star_empty.svg"
          alt="Empty star"
          className="w-full h-full"
        />

        {/* Filled Star */}
        <img
          src="/images/assets/star_filled.svg"
          alt="Filled star"
          className="absolute top-0 left-0 w-full h-full"
          style={{
            clipPath: `inset(0% ${100 - fillPercentage}% 0% 0%)`,
            transition: animatedWidth
              ? `clip-path ${transitionDuration}s ${timingFunction} ${transitionDelay}`
              : 'none',
          }}
        />
      </div>
    );
  }

  return <div className="flex gap-1">{stars}</div>;
}
