import React, { useEffect, useRef, useState } from "react";
import { postPlayerMoment } from "../../services/playerService/player.service";

const LibanoVideoPlayer = ({
  autoPlay,
  sessionId,
  disciplineId,
  classId,
  courseId,
  className,
  src,
  time,
  backgroundVideo,
  style,
  courseAlias,
  rateCourseBox
}) => {
  const [videoStatus, setVideoStatus] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Ref para valores de currentTime e duration
  const currentTimeRef = useRef(0);
  const durationRef = useRef(0);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      try {
        const containerWidth = containerRef.current.offsetWidth;
        const newHeight = (9 / 16) * containerWidth;
        containerRef.current.style.height = `${newHeight + 3}px`;
      } catch (error) {
        console.error(`Player Resize Error - ${error.message}`);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data && event.data.event) {
        switch (event.data.event) {
          case "play":
            setVideoStatus("playing");
            break;
          case "pause":
            setVideoStatus("paused");
            break;
          case "time":
            setCurrentTime(event.data.currentTime);
            setDuration(event.data.duration);

            // Atualize as refs diretamente
            currentTimeRef.current = event.data.currentTime;
            durationRef.current = event.data.duration;
            break;
          case "end":
            // Uncomment the following line if you want to redirect after the video ends
            // if (rateCourseBox) window.location.href = `/rateCourse/${courseAlias}`;
            break;
          default:
            console.log("Unrecognized event:", event.data.event);
        }
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        !backgroundVideo &&
        sessionId &&
        classId &&
        courseId &&
        disciplineId &&
        videoStatus === "playing"
      ) {
        postPlayerMoment({
          sessionId: sessionId,
          watchedSeconds: Math.floor(currentTimeRef.current),
          totalSeconds: Math.floor(durationRef.current),
          disciplineId: disciplineId,
          classId: classId,
          courseId: courseId
        })
          .then(() => {})
          .catch((error) => {
            console.error("Error in postPlayerMoment:", error);
          });
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    backgroundVideo,
    sessionId,
    classId,
    courseId,
    disciplineId,
    videoStatus
  ]);

  return (
    <div
      ref={containerRef}
      style={style}
      id="video-player-container"
      className={className ?? "w-full h-full"}>
      <iframe
        allowFullScreen={"0"}
        className="rounded-[0.5rem] max-lg:rounded-none aspect-video h-fit"
        src={`${src}?start=${time ?? "0"}${
          autoPlay
            ? "?autoplay=true&mute=true&restartAfterEnd=true&hideControls=false"
            : ""
        }`}
        style={{
          border: "0",
          width: "100%"
        }}
      />
    </div>
  );
};

export default LibanoVideoPlayer;
