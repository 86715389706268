import React, { useEffect, useRef } from "react";
import { postPlayerMoment } from "../../services/playerService/player.service";

const IframeVideoPlayer = ({
  autoPlay,
  backgroundVideo,
  sessionId,
  disciplineId,
  classId,
  courseId,
  courseAlias,
  rateCourseBox,
  src,
  style,
  time
}) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.offsetWidth;
      const newHeight = (9 / 16) * containerWidth;
      containerRef.current.style.height = `${newHeight}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleMetadataLoaded = () => {
      if (time) {
        videoElement.currentTime = time;
      }
    };

    let intervalId;
    let videoStatus = "paused";

    const handlePlay = () => {
      videoStatus = "playing";
    };

    const handlePause = () => {
      videoStatus = "paused";
    };

    // const handleEnd = () => {
    //   if (rateCourseBox) window.location.href = `/rateCourse/${courseAlias}`;
    // };

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
      // videoElement.addEventListener("ended", handleEnd);

      intervalId = setInterval(async () => {
        if (
          !backgroundVideo &&
          sessionId &&
          classId &&
          courseId &&
          disciplineId &&
          videoStatus === "playing"
        ) {
          postPlayerMoment({
            sessionId: sessionId,
            watchedSeconds: Math.floor(videoElement.currentTime),
            totalSeconds: Math.floor(videoElement.duration),
            disciplineId: disciplineId,
            classId: classId,
            courseId: courseId
          });
        }
      }, 5000);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
        // videoElement.removeEventListener("ended", handleEnd);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sessionId, classId, courseId, disciplineId, backgroundVideo, time]);

  useEffect(() => {
    const handleOrientationChange = () => {
      try {
        if (window.screen.orientation.type.startsWith("landscape")) {
          if (containerRef.current.requestFullscreen) {
            const variable = videoRef.current.requestFullscreen();
          } else if (containerRef.current.mozRequestFullScreen) {
            videoRef.current.mozRequestFullScreen();
          } else if (containerRef.current.webkitRequestFullscreen) {
            videoRef.current.webkitRequestFullscreen();
          } else if (containerRef.current.msRequestFullscreen) {
            videoRef.current.msRequestFullscreen();
          }
        } else {
          if (
            videoRef.current.fullscreenElement ||
            videoRef.current.webkitFullscreenElement ||
            videoRef.current.mozFullScreenElement ||
            videoRef.current.msFullscreenElement
          ) {
            if (videoRef.current.exitFullscreen) {
              videoRef.current.exitFullscreen();
            } else if (videoRef.current.mozCancelFullScreen) {
              videoRef.current.mozCancelFullScreen();
            } else if (videoRef.current.webkitExitFullscreen) {
              videoRef.current.webkitExitFullscreen();
            } else if (videoRef.current.msExitFullscreen) {
              videoRef.current.msExitFullscreen();
            }
          }
        }
      } catch (error) {
        console.error(`Player Orientation Error - ${error.message}`);
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", overflow: "hidden", ...style }}>
      <video
        ref={videoRef}
        src={src}
        style={{ border: "0", width: "100%", height: "100%" }}
        autoPlay={autoPlay}
        controls
        allowfullscreen="0"
        controlsList="nodownload"
      />
    </div>
  );
};

export default IframeVideoPlayer;
