// ModalVideoPlayer.jsx

import React, { useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";

export default function ModalVideoPlayer({
  isVisible,
  appearVideo,
  appearTime,
  horizontalMode,
  disableFadeOut,
  videoFirstFrame,
  modalContentStyle,
  videoSrc,
  thumbSrc,
  closeVideo
}) {
  const [playerClasses, setPlayerClasses] = useState("");
  if (!isVisible) return null;

  const handleCloseVideo = () => {
    if (!disableFadeOut) {
      setPlayerClasses("animate-disappear");
      setTimeout(() => {
        setPlayerClasses("");
        closeVideo();
      }, 300);
    } else closeVideo();
  };
  return (
    <div
      className={`modal-overlay animate-appear ${
        !appearVideo && !appearTime && "animate-disappear"
      } ${playerClasses}`}
      onClick={handleCloseVideo}>
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-0 origin-center transition-transform duration-300 ease-in-out z-[101] modal-content ${
          horizontalMode ? "w-[80%] aspect-video" : "w-[335px] h-[592px]"
        } ${appearVideo ? "show" : ""}`}
        style={modalContentStyle}
        onClick={(e) => e.stopPropagation()}>
        <button
          className="absolute top-3 right-3 bg-gray-800 text-white rounded-full z-[101] w-6 h-6 flex items-center justify-center"
          onClick={handleCloseVideo}>
          <HiMiniXMark size={18} />
        </button>
        <div
          className={`video-content select-none ${
            appearVideo ? "show" : ""
          } rounded-[0.8rem] overflow-hidden`}>
          {videoSrc && (
            <video
              src={videoSrc}
              controls
              autoPlay
              className="rounded-[0.8rem] absolute w-full h-full select-none object-cover"
            />
          )}
        </div>
        {videoFirstFrame ? (
          <video
            autoPlay={false}
            muted={true}
            src={thumbSrc}
            className={`w-full h-full object-cover rounded-[0.8rem] transition-300 ${
              appearVideo && "opacity-0"
            }`}
          />
        ) : (
          <img
            src={thumbSrc}
            className={`w-full h-full object-cover rounded-[0.8rem] transition-300 ${
              appearVideo && "opacity-0"
            }`}
          />
        )}
      </div>
    </div>
  );
}
