import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./AdsVideoPlayer.css";
import { BsSkipEndFill } from "react-icons/bs";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { FaPlay } from "react-icons/fa";
import { putAdsStatus } from "../../services/adsServices/ads.service";

const AdsVideoPlayer = ({
  studentInfos,
  skipVideo,
  title,
  buttonName,
  buttonLink,
  videoLink,
  videoThumb,
  description,
  onCloseAds,
  campaignId,
  adId,
  style
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [canSkip, setCanSkip] = useState(false);
  const [videoSeconds, setVideoSeconds] = useState(0);
  const [videoSecond, setVideoSecond] = useState(0);
  const videoNode = useRef(null);
  const player = useRef(null);
  const containerRef = useRef(null);
  const [disappearClass, setDisappearClass] = useState("");

  const handleVideoClick = () => {
    const playButton = document.querySelector(
      'button[title="Play"], button[title="Pause"]'
    );
    if (playButton) playButton.click();
  };

  useEffect(() => {
    if (isPlaying && videoNode.current) {
      player.current = videojs(videoNode.current, {
        controls: true,
        autoplay: true,
        preload: "auto",
        inactivityTimeout: 500,
        bigPlayButton: false,
        controlBar: {
          fullscreenToggle: false,
          playToggle: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          volumePanel: false,
          remainingTimeDisplay: false,
          progressControl: true
        },
        userActions: {
          click: true,
          doubleClick: false,
          hotkeys: false
        }
      });

      let preventSeek = true;
      let lastTime = 0;

      player.current.on("timeupdate", function () {
        const currentTime = player.current.currentTime();
        setVideoSecond(Math.floor(currentTime));
        if (currentTime >= 5 && !canSkip) {
          setCanSkip(true);
        }
        lastTime = currentTime;
      });

      player.current.on("seeking", function () {
        if (preventSeek) {
          player.current.currentTime(lastTime);
        }
      });

      player.current.on("ended", () => {
        setDisappearClass("transition-300 opacity-0");
        setTimeout(() => {
          onCloseAds();
        }, 300);
      });

      player.current.on("loadedmetadata", function () {
        setVideoSeconds(Math.floor(player.current.duration()));
      });

      player.current.src({ src: videoLink, type: "video/mp4" });

      return () => {
        if (player.current) player.current.dispose();
      };
    }
  }, [isPlaying]);

  const handleThumbClick = () => {
    handleRangeRegister();
    setIsPlaying(true);
  };

  useEffect(() => {
    const handleResize = () => {
      try {
        const containerWidth = containerRef.current.offsetWidth;
        const newHeight = (9 / 16) * containerWidth;
        containerRef.current.style.height = `${newHeight + 3}px`;
      } catch (error) {
        console.error(`Player Resize Error - ${error.message}`);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickRegister = () => {
    putAdsStatus({
      studentInfos,
      campaignId,
      adId,
      newStatus: "quantityAccess"
    });
  };

  const handleSkipRegister = () => {
    putAdsStatus({
      studentInfos,
      campaignId,
      adId,
      newStatus: "quantityRejected"
    });
  };

  const handleRangeRegister = () => {
    putAdsStatus({
      studentInfos,
      campaignId,
      adId,
      newStatus: "quantityRange"
    });
  };

  // const formatRemainingSeconds = (videoSeconds) => {
  //   try {
  //     const actualSecond = Number(videoSecond);
  //     const remainingSeconds = Number(videoSeconds) - Number(actualSecond);
  //     const minutes = Math.floor(remainingSeconds / 60);
  //     const seconds = remainingSeconds % 60;
  //     if (
  //       videoSeconds < 0 ||
  //       remainingSeconds < 0 ||
  //       seconds < 0 ||
  //       minutes < 0
  //     )
  //       return "";
  //     return `• ${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  //   } catch (error) {
  //     console.error(`Format Remaining Seconds Error - ${error.message}`);
  //     return "";
  //   }
  // };

  return (
    <div ref={containerRef} style={style} className="relative w-full">
      <div className="absolute top-0 left-0 w-full h-full">
        {isPlaying && (
          <div
            title="mobile-toggle-pause"
            onClick={handleVideoClick}
            className="absolute top-0 left-0 w-screen z-[1] md:hidden"
            style={{ aspectRatio: 16 / 7 }}
          />
        )}
        <div data-vjs-player>
          <video
            ref={videoNode}
            className={`video-js w-full h-full aspect-video mt-[-2px] ${disappearClass}`}
          />
        </div>
        <div
          className={`flex items-center absolute top-[5px] left-0 px-8 py-6 max-md:top-[16px] max-md:pl-[13vw] max-md:py-0`}>
          <span className="text-base leading-tight font-medium">{title}</span>
        </div>

        <div
          className={`flex items-center absolute bottom-[30px] left-0 w-full justify-between px-4 py-2 pointer-events-none max-md:justify-end`}>
          <div
            className={`flex gap-4 text-white w-fit max-w-[500px] pointer-events-auto bg-backgroundTwo60 p-4 rounded-xl justify-between max-md:hidden mb-8 relative`}>
            <div className="absolute -bottom-6 left-4">
              <span className="text-xs opacity-70 font-bold">
                Anúncio
              </span>
            </div>
            <div className="flex flex-col pr-4 justify-center">
              <h3 className="text-lg font-semibold">{title}</h3>
              <p className="text-xs leading-none font-normal line-clamp-3 break-all">{description}</p>
            </div>
            <div className="flex items-center justify-end">
              <ButtonPrimary
                removeTextPadding
                verifyOnClick={() => {
                  handleClickRegister();
                  window.open(buttonLink, "_blank");
                }}
                textButton={buttonName}
                desktopWidth={140}
              />
            </div>
          </div>
          {skipVideo && isPlaying && (
            <div className="text-white pointer-events-auto md:mb-8 z-[2]">
              <button
                className={`flex items-center bg-black bg-opacity-30 overflow-hidden p-1.5 rounded-[12px] transition-600 text-sm ${
                  canSkip ? "hover:bg-opacity-50" : "cursor-default"
                } ${disappearClass}`}
                onClick={() => {
                  if (!canSkip) return;
                  handleSkipRegister();
                  setDisappearClass("transition-300 opacity-0");
                  setTimeout(() => {
                    onCloseAds();
                  }, 300);
                }}>
                <div
                  className={`relative transition-600 pointer-events-none ${
                    canSkip ? "w-[90px] max-md:w-[80px]" : "w-[36px]"
                  }`}>
                  <div
                    className={`flex absolute top-0 left-0 h-full w-full items-center justify-center transition-600 ${
                      canSkip ? "opacity-0" : ""
                    }`}>
                    <p className="w-fit text-lg leading-none max-md:text-md">
                      {5 - videoSecond > 0 ? 5 - videoSecond : 0}
                    </p>
                  </div>
                  <div
                    className={`flex absolute top-0 left-0 h-full w-full items-center justify-center transition-600 gap-1 ${
                      canSkip ? "" : "opacity-0"
                    }`}>
                    <BsSkipEndFill
                      className="min-w-[24px] max-md:scale-75"
                      size={24}
                    />
                    <p className="w-fit text-lg leading-none max-md:text-sm">
                      Pular
                    </p>
                  </div>
                </div>
                <img
                  src={videoThumb}
                  className="w-[64px] rounded-md aspect-video ml-1.5 max-md:w-[48px]"
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`absolute top-0 left-0 w-full h-full cursor-pointer transition-opacity duration-300 ${
          isPlaying && "opacity-0 pointer-events-none"
        }`}
        onClick={handleThumbClick}>
        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full">
          <div className="flex items-center justify-center w-[100px] h-[100px] rounded-full bg-themeColor opacity-90" />
          <FaPlay size={50} className="text-textPrimary absolute" />
        </div>
        <img
          src={videoThumb}
          alt="Video Thumbnail"
          className="w-full h-full object-cover"
        />
      </div>
      {videoNode.current && isPlaying && (
        <div
          className={`absolute z-[2] flex gap-3 text-white w-full pointer-events-auto bg-backgroundTwo px-[5vw] py-4 justify-between transition-600 ${
            !disappearClass && "fadeInOpacityClass"
          } ${disappearClass} md:hidden`}
          style={{
            marginTop: videoNode.current.offsetHeight - 4 || 0
          }}>
          <div className="flex flex-col justify-center">
            <h3 className="text-base leading-none font-medium">{title}</h3>
            <p className="text-xs text-textSecondary leading-tight font-normal max-lg:line-clamp-3">
              {description}
            </p>
          </div>
          <div className="flex items-center min-w-[30%] justify-end">
            <ButtonPrimary
              removeTextPadding
              verifyOnClick={() => {
                handleClickRegister();
                window.open(buttonLink, "_blank");
              }}
              textButton={buttonName}
            />
          </div>
        </div>
      )}
      <div
        className={`absolute bottom-[-100vh] left-0 h-screen z-[1] w-full bg-black/90 backdrop-blur-[3px] transition-600 ${
          !isPlaying && "opacity-0"
        } ${disappearClass} md:hidden`}
      />
    </div>
  );
};

export default AdsVideoPlayer;
