import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import CryptoJS from "crypto-js";
import { certifier } from "../../mocks/certifier";
import { Toastify } from "../../components/Toastify/Toastify";

export const getCourseByAlias = async (data) => {
  try {
    const sessionId = data.sessionId;
    const courseAlias = data.courseAlias;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        courseAlias: courseAlias,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getCourseByAlias`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const updateRateClass = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    if (!data.content.quantityStars)
      return Toastify("error", "Nota não definida.");
    const cryptoInfos = await CryptoService(
      {
        courseId: data.content.courseId,
        disciplineId: data.content.disciplineId,
        classId: data.content.classId,
        quantityStars: data.content.quantityStars,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/classEvaluation`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const updateRateCourse = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        courseAlias: data.content.courseAlias,
        quantityStars: data.content.quantityStars,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/evaluationCourseIfNotClass`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const updateInternalRateCourse = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/assessmentGoogle/${data.content.quantityStars}`,
      null,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    return {
      data: responseCrypt.data.data,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const updateCourseTestimony = async (data) => {
  try {
    const sessionId = data.sessionId;
    if (!data.alreadyRated && data.content.quantityStars) {
      await updateRateCourse({
        sessionId: sessionId,
        content: {
          courseAlias: data.content.courseAlias,
          quantityStars: data.content.quantityStars
        }
      });
    }

    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        description: data.content.description,
        courseAlias: data.content.courseAlias,
        videoUrl: data.content.videoUrl,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/evaluationCourse`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getRateCourse = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        courseAlias: data.courseAlias,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/getPortalEvaluationClass`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getRateCourseTestimony = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        courseAlias: data.courseAlias,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/getLastForEvaluationsCourse`,
      body,
      config
  
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getAICourses = async (data) => {
  try {
    const sessionId = data.sessionId;
    const studentPrompt = data.studentPrompt;
    const post_data = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        studentPrompt,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getAICourses`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { response: err.response.data.data, status: err.response.data.code };
  }
};

export const getAllStudentCourses = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId: sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getAllStudentCourses`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getListStudentCourses = async (data) => {
  try {
    const sessionId = data.sessionId;
    const post_data = await CryptoService(
      {
        sessionId: sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getListStudentCourses`,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getPublicCourseInfo = async (data) => {
  try {
    const courseAPIData = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/getCourseWebSite`,
      {
        alias: data
      }
    );
    if (courseAPIData.status !== 200) {
      return { status: courseAPIData.status };
    }
    return {
      data: courseAPIData.data.data,
      status: courseAPIData.status
    };
  } catch (err) {
    console.error("errorrr22", err);
    return { status: err.response.data.code };
  }
};

export const requestCourseGetWithAuth = async (apiUrl) => {
  try {
    const cryptoHeaderContent = {
      web_site: "NEXT",
      exp: moment().add(20, "m").format()
    };
    let ciphertext = await CryptoJS.AES.encrypt(
      JSON.stringify(cryptoHeaderContent),
      `${certifier.generalSettings.cryptoKey}`
    ).toString();
    const response = await axios.get(apiUrl, {
      headers: { auth: ciphertext }
    });
    return {
      data: response.data,
      status: 200
    };
  } catch (error) {
    console.error("errorrr111", error.response.status);
    return { status: error.response.status };
  }
};
