import React, { useEffect, useState, useRef } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { useParams } from "react-router-dom";
import {
  getListStudentCourses,
  getRateCourse,
  updateCourseTestimony
} from "../../services/courseService/course.service";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";
import * as Sentry from "@sentry/react";
import {
  assembleChunks,
  uploadChunk
} from "../../services/videoService/video.service";
import ModalVideoPlayer from "../../components/ModalVideoPlayer/ModalVideoPlayer";

function RateCoursePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [studentInfos, setStudentInfos] = useState({});
  const [windowWidth, setWindowWidth] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);
  const [starsState, setStarsState] = useState(-1);
  const [starsHoverState, setStarsHoverState] = useState(-1);
  const [starAnimationTime, setStarAnimationTime] = useState(undefined);
  const [dragOver, setDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [alreadyRated, setAlreadyRated] = useState(false);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);
  const [mainTextarea, setMainTextarea] = useState("");
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [appearVideo, setAppearVideo] = useState(false);
  const [appearTime, setAppearTime] = useState(false);
  const [sentStatus, setSentStatus] = useState(false);

  const courseAlias = useParams().courseAlias;

  const handleOpenVideo = () => {
    setIsVideoModalVisible(true);
    setAppearVideo(true);
    setAppearTime(true);
  };

  const closeVideo = () => {
    setIsVideoModalVisible(false);
    setAppearVideo(false);
    setAppearTime(false);
  };

  const handleSubmitRate = async () => {
    if (sentStatus) window.location.href = "/dashboard";
    setIsLoading(true);
    const content = {
      description: mainTextarea.trim(),
      courseAlias,
      videoUrl: uploadedVideoUrl,
      quantityStars: starsState,
    };

    const response = await updateCourseTestimony({
      sessionId: studentInfos.sessionId,
      content,
      alreadyRated
    });

    if (response.status === 200) {
      Toastify("success", "Curso avaliado com sucesso.");
      setSentStatus(true);
    } else {
      Toastify("error", "Erro ao avaliar o curso.");
    }
    setIsLoading(false);
  };

  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchStudentCourses = async () => {
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        setStudentInfos(student);
        // getAllStudentCourses --- START
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId
        });
        if (coursesResponse.status !== 200) {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
          console.error(
            "Erro ao buscar informações de todos os cursos - 1:",
            coursesResponse.status
          );
        }
        let coursesOptions = coursesResponse.data;
        if (
          coursesOptions.find(
            (course) => course.courseAlias === courseAlias
          ) === undefined
        ) {
          window.location.href = `/dashboard?message=${encodeURIComponent(
            `Você não tem permissão para avaliar este curso.`
          )}`;
        }

        const alreadyRatedCourse = await getRateCourse({
          sessionId: student.sessionId,
          courseAlias: courseAlias
        });

        setAlreadyRated(alreadyRatedCourse.data.existEvaluation);

        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
        setIsLoading(false);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar seus cursos.");
        console.error("Erro ao buscar os cursos: ", error);
        setIsLoading(false);
      }
    };
    fetchStudentCourses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFileUpload = (file) => {
    if (file && file.type.startsWith("video/")) {
      if (file.size > 150 * 1024 * 1024) {
        Toastify("error", "O arquivo deve ser menor que 150MB.");
        return;
      }
      setUploadProgress(1);
      uploadFileInChunks(file);
    } else {
      console.error(`file error`, file);
      Toastify("error", "Por favor, selecione um arquivo de vídeo válido.");
    }
  };

  const uploadFileInChunks = (file) => {
    const chunkSize = 1024 * 1024; // 1MB chunks
    const totalChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const videoId = generateUUID();

    const sessionId = studentInfos.sessionId;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const chunkData = e.target.result;

      try {
        const response = await uploadChunk({
          sessionId,
          chunkData,
          chunkIndex: currentChunk,
          totalChunks,
          videoId,
          isPublic: true
        });

        if (response.status === 200) {
          currentChunk++;
          setUploadProgress(
            Math.round((currentChunk / totalChunks) * 100 * 0.97)
          );
          if (currentChunk < totalChunks) {
            readNextChunk();
          } else {
            const assembleResponse = await assembleChunks({
              sessionId,
              videoId,
              totalChunks,
              originalFileName: file.name,
              isPublic: true
            });

            if (assembleResponse.status !== 201) {
              const errorMessage =
                assembleResponse.data.message || "Unknown error";
              Toastify("error", `Erro ao finalizar upload: ${errorMessage}`);
              return;
            }

            Toastify("success", `Upload realizado com sucesso!`);

            setUploadedVideoUrl(assembleResponse.data.data.public_url);

            setUploadProgress(100);
          }
        } else {
          Toastify("error", "Erro ao enviar o arquivo!");
          setUploadProgress(0);
        }
      } catch (error) {
        if (error.response && String(error.response.status) === "401") {
          Sentry.setUser(null);
          localStorage.removeItem("user");
          if (window) window.location.reload();
          return;
        }
        Toastify("error", "Erro ao enviar o arquivo.");
        setUploadProgress(0);
      }
    };

    reader.onerror = () => {
      Toastify("error", "Erro ao ler o arquivo.");
      setUploadProgress(0);
    };

    const readNextChunk = () => {
      if (currentChunk >= totalChunks) {
        return;
      }
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const blob = file.slice(start, end);
      reader.readAsArrayBuffer(blob);
    };

    readNextChunk();
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne h-full min-h-screen items-center text-textPrimary">
      <HeaderBar
        courseNavigation={false}
        mobileMainText="TCC"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar />
      <LoadingSection isLoading={isLoading} />
      <section className="mt-9 mb-9 pl-0 overflow-hidden flex w-full h-full min-h-[600px] max-w-[1170px] justify-center max-mb:min-h-[100vh] bg-backgroundTwo rounded-xl max-mb:mt-0 max-mb:mb-0 max-mb:rounded-none max-mb:flex-col">
        <div
          style={{
            paddingTop: windowWidth < 960 ? navBarHeader : 0
            // paddingBottom: windowWidth < 960 ? navBar : 0
          }}
          className={`h-auto w-[65%] max-mb:w-full`}>
          <div
            className={`p-32 py-16 relative h-full flex w-full bg-backgroundTwo max-mb:p-8 max-mb:px-[5vw]`}>
            <div className="flex flex-col gap-y-8 w-full h-full">
              <div className="flex flex-col gap-y-4">
                <div>
                  <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mb:text-4xl">
                    {sentStatus
                      ? "Seu feedback é extremamente importante!"
                      : `Parabéns ${
                          studentInfos.name
                            ? studentInfos.name.split(" ")[0]
                            : "..."
                        }! Você terminou de assistir todas as suas aulas!`}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // paddingTop: windowWidth < 960 ? navBarHeader : 0,
            paddingBottom: windowWidth < 960 ? navBar : 0
          }}
          className={`bg-backgroundThree h-auto w-[38%] max-mb:w-full max-mb:bg-transparent max-mb:`}>
          <div
            className={`flex w-full h-full flex-col m-0 p-16 max-mb:px-[5vw] max-mb:p-8 justify-between max-mb:pt-0`}>
            <div className="flex flex-col gap-12 max-mb:gap-8">
              <div>
                <h1 className="text-3xl text-textPrimary font-bold leading-[1.35] max-mb:hidden">
                  {sentStatus ? "Obrigado!" : "Feedback"}
                </h1>
                <p className="text-textPrimary text-sm max-mb:text-[#575757]">
                  {sentStatus
                    ? "Ao compartilhar sua experiência, você permite que outros compreendam os benefícios do aprendizado."
                    : "Compartilhe com a gente como foi sua experiencia com o nosso conteúdo e como ele pode impactar em sua carreira."}
                </p>
              </div>
              {!alreadyRated && !sentStatus && (
                <div className="flex h-full gap-x-4 items-center justify-center max-mb:gap-x-6">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div
                      className="transition-300 hover:scale-[1.15]"
                      key={index}>
                      <div
                        className={`${
                          starAnimationTime === index + 1 &&
                          "translate-y-[4px] translate-x-[1px] transition-300"
                        }`}>
                        <div
                          className={`origin-center w-[42px] h-[42px] cursor-pointer ${
                            starAnimationTime === index + 1 &&
                            "rotate-[144deg] transition-300"
                          } max-mb:w-[36px] max-mb:h-[36px]`}
                          onMouseLeave={() => setStarsHoverState(0)}
                          onMouseEnter={() => setStarsHoverState(index + 1)}
                          onClick={async () => {
                            setStarsState(index + 1);
                            setStarAnimationTime(index + 1);
                          }}>
                          <img
                            src="/images/assets/star_empty.svg"
                            className={`select-none absolute w-[42px] h-[42px] max-mb:w-[36px] max-mb:h-[36px] transition-600 ${
                              starsState <= index
                                ? "opacity-100"
                                : "opacity-100"
                            }`}
                          />
                          <img
                            src="/images/assets/star_filled.svg"
                            className={`select-none absolute w-[42px] h-[42px] max-mb:w-[36px] max-mb:h-[36px] transition-600 ${
                              starsState > index
                                ? "opacity-100"
                                : starsHoverState > index
                                ? "opacity-100"
                                : "opacity-0"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!sentStatus && (
                <>
                  <div className="flex flex-col gap-3">
                    <label className="span-small-white">
                      Adicionar avaliação
                    </label>
                    <textarea
                      id="mainTextarea"
                      maxLength={1000}
                      placeholder="Digite seu feedback"
                      onChange={(e) => {
                        setMainTextarea(e.target.value);
                      }}
                      className="w-full bg-transparent p-3 resize-none text-sm rounded-xl border-2 border-[#4F4F4F] placeholder:text-[#4F4F4F]"
                      style={{ minHeight: "10em" }}
                    />
                  </div>
                  <div className="flex w-full justify-end">
                    <ButtonPrimary
                      textButton="Instruções"
                      desktopWidth="50%"
                      mobileWidth="50%"
                      outlineType
                      outlineColor={certifier.colors.text.textPrimary}
                      optionalTextColor={certifier.colors.text.textPrimary}
                      verifyOnClick={handleOpenVideo}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <label className="span-small-white">
                      Enviar vídeo de feedback
                    </label>
                    <div
                      className={`flex flex-col gap-3 text-center w-full p-3 resize-none rounded-xl bg-[#26262E] border-2 transition-300 ${
                        uploadProgress >= 100 ? "pt-4" : "border-dashed"
                      } ${
                        dragOver ? "border-blue-500" : "border-[#4F4F4F]"
                      } select-none cursor-pointer relative`}
                      style={{ minHeight: "10em" }}
                      onClick={() => fileInputRef.current.click()}
                      onDragOver={(e) => {
                        e.preventDefault();
                        setDragOver(true);
                      }}
                      onDragLeave={(e) => {
                        e.preventDefault();
                        setDragOver(false);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        setDragOver(false);
                        if (
                          e.dataTransfer.files &&
                          e.dataTransfer.files.length > 0
                        ) {
                          handleFileUpload(e.dataTransfer.files[0]);
                          e.dataTransfer.clearData();
                        }
                      }}>
                      <input
                        type="file"
                        accept="video/*"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length > 0) {
                            handleFileUpload(e.target.files[0]);
                          }
                        }}
                      />
                      <div className="flex w-full justify-center text-center">
                        <img
                          src="/images/assets/video_upload.svg"
                          className="mix-blend-overlay pointer-events-none"
                        />
                      </div>
                      <span className="text-xs">
                        {uploadProgress >= 100
                          ? "Sucesso!"
                          : "Carregue ou arraste e solte o arquivo do vídeo com seu depoimento"}
                      </span>
                      <span className="text-[0.625rem]">
                        {uploadProgress >= 100
                          ? "Upload concluído."
                          : "Tipo de arquivos suportado - Arquivos de até 150 MB"}
                      </span>
                      <div
                        className={`flex flex-col gap-3 pt-[22px] items-center justify-center w-full h-full absolute top-0 left-0 rounded-xl backdrop-blur-sm bg-black/30 px-5 transition-300 ${
                          !uploadProgress > 0 && "opacity-0"
                        } ${uploadProgress >= 100 && "opacity-0"}`}>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
                          <div
                            className="bg-themeColor h-2.5 transition-600"
                            style={{ width: `${uploadProgress}%` }}></div>
                        </div>
                        <span>{uploadProgress}%</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex w-full">
              <div className="flex gap-5 w-full justify-end max-sm:justify-between pt-12 max-mb:py-8 max-mb:flex-col-reverse">
                <div className="w-full">
                  <ButtonPrimary
                    optionalTextColor={
                      certifier.colors.buttons.buttonTertiary_darkTheme
                        ? "#000"
                        : "#fff"
                    }
                    textButton={sentStatus ? "Curso" : "Voltar"}
                    outlineType={window.innerWidth < 960 ? true : false}
                    outlineColor={"#fff"}
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    mobileWidth="100%"
                    desktopWidth="100%"
                    verifyOnClick={() =>
                      (window.location.href = `/course/${courseAlias}`)
                    }
                  />
                </div>
                <div className="w-full">
                  <ButtonPrimary
                    textButton={sentStatus ? "Início" : "Enviar"}
                    mobileWidth="100%"
                    desktopWidth="100%"
                    verifyOnClick={handleSubmitRate}
                    disabled={
                      (uploadProgress > 0 && uploadProgress < 100) ||
                      (!alreadyRated && starsState <= 0) ||
                      (alreadyRated &&
                        (!mainTextarea ||
                          mainTextarea.length > 1000 ||
                          mainTextarea.length < 3) &&
                        !uploadedVideoUrl)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalVideoPlayer
        isVisible={isVideoModalVisible}
        appearVideo={appearVideo}
        appearTime={appearTime}
        horizontalMode
        videoSrc="https://www.w3schools.com/html/mov_bbb.mp4"
        thumbSrc="https://via.placeholder.com/150"
        closeVideo={closeVideo}
      />{" "}
    </main>
  );
}

export default verifyAuth(RateCoursePage);
