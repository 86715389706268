import { useEffect, useState } from "react";
import { updateRateClass } from "../../services/courseService/course.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";

export default function RateClass({ classContent, disciplineId, classId }) {
  const [divState, setDivState] = useState("initial");
  const [starsState, setStarsState] = useState(-1);
  const [starsText, setStarsText] = useState(-1);
  const [starsHoverState, setStarsHoverState] = useState(-1);
  const [clickAnimationTime, setClickAnimationTime] = useState(false);
  const [starAnimationTime, setStarAnimationTime] = useState(false);

  useEffect(() => {
    if (
      classContent &&
      classContent.currentClass &&
      classContent.currentClass.quantityStars
    ) {
      setStarsState(classContent.currentClass.quantityStars);
      setStarsText(classContent.currentClass.quantityStars);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (divState === "thanks") {
      const timer = setTimeout(() => {
        setDivState("initial");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [divState]);

  return (
    <div
      style={{ animation: "fadeInOpacity 0.6s ease forwards" }}
      className="relative group z-[13]"
      onMouseEnter={() =>
        setDivState((prev) =>
          prev === "opened_stars"
            ? "opened_stars"
            : prev === "thanks"
            ? "thanks"
            : "hover"
        )
      }
      onMouseMove={() =>
        setDivState((prev) =>
          prev === "opened_stars"
            ? "opened_stars"
            : prev === "thanks"
            ? "thanks"
            : "hover"
        )
      }
      onMouseLeave={() =>
        setDivState((prev) =>
          prev === "hover"
            ? "initial"
            : prev === "thanks"
            ? "thanks"
            : "opened_stars"
        )
      }
      onClick={() =>
        setDivState((prev) =>
          prev === "opened_stars" ? "initial" : "opened_stars"
        )
      }>
      <div
        className={`w-[100px] h-[44px] relative rounded-full cursor-pointer z-[13] transition-300 ${
          (divState === "initial" || divState === "thanks") &&
          "bg-backgroundThree"
        } ${
          (divState === "hover" || divState === "opened_stars") &&
          "bg-white border-white"
        } group-hover:translate-y-[-2px] max-md:h-[40px]`}>
        {/* Container de estrela-exemplo */}
        <div
          className={`absolute origin-center w-[18px] h-[18px] top-[12px] transition-600 ${
            (divState === "initial" ||
              divState === "hover" ||
              divState === "opened_stars") &&
            starsState === -1
              ? "translate-x-[24px]"
              : "translate-x-[30px]"
          } ${divState === "thanks" && "translate-x-[32px]"} ${
            (divState === "opened_stars" || divState === "thanks") &&
            "rotate-[144deg] translate-y-[1px]"
          }
      max-md:top-[10px]`}>
          <img
            src="/images/assets/star_outline.svg"
            className={`select-none absolute w-[18px] h-[18px] transition-600 ${
              (divState !== "initial" || starsState > 0) && "opacity-0"
            }`}
            alt="Estrela Vazia"
          />
          <img
            src="/images/assets/star_filled.svg"
            className={`select-none absolute w-[18px] h-[18px] transition-600 ${
              divState === "initial" && starsState <= 0 && "opacity-0"
            } ${clickAnimationTime && "animate-clickAnimation"}`}
            alt="Estrela Cheia"
          />
        </div>
        {/* Container Textual */}
        <div
          className={`absolute top-[10px] translate-x-[50px] transition-300 ${
            starsState !== -1 && "opacity-0"
          } max-md:top-[8px]`}>
          <div className="flex text-[#7F7F81] font-medium items-end">
            <span className="-mb-[2px]">0</span>
            <span className="-mb-[2px]">/</span>
            <span className="text-[10px]">5</span>
          </div>
        </div>
        <div
          className={`absolute top-[11px] translate-x-[58px] transition-300 ${
            starsState === -1 || clickAnimationTime ? "opacity-0" : ""
          } max-md:top-[8px]`}>
          <div className="flex text-[#7F7F81] font-medium items-end">
            <span className={`select-none`}>{starsText}</span>
          </div>
        </div>
      </div>
      {/* Container de tooltip */}
      <div
        className={`absolute flex items-center justify-center top-0 -ml-[20%] w-[140%] bg-backgroundTwo p-2 rounded-lg z-[11] pointer-events-none transition-600 ${
          divState === "hover"
            ? "translate-y-[50px]"
            : "translate-y-[10px] opacity-0"
        }`}>
        <span className="text-xs leading-none">
          {starsState > 0 ? "Avaliar Novamente" : "Avaliar Aula"}
        </span>
      </div>
      {/* Container de estrelas */}
      <div
        className={`absolute top-0 -ml-[20%] w-[140%] h-[40px] bg-backgroundTwo py-1 px-2 rounded-full z-[12] transition-600 ${
          divState === "opened_stars"
            ? "translate-y-[50px] delay-300"
            : "translate-y-[10px] opacity-0 delay-300"
        } ${clickAnimationTime && "pointer-events-none"}`}>
        <div className="flex h-full gap-x-1 items-center justify-center">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              className={`origin-center w-[18px] h-[18px] top-[12px] cursor-pointer transition-300 hover:scale-[1.15] ${
                starAnimationTime === index + 1 &&
                "rotate-[144deg] translate-y-[1px]"
              }            
          `}
              onMouseLeave={() => setStarsHoverState(0)}
              onMouseEnter={() => setStarsHoverState(index + 1)}
              onClick={async () => {
                const student = await getStudentInfos();
                updateRateClass({
                  sessionId: student.sessionId,
                  content: {
                    courseId: classContent.courseId,
                    disciplineId,
                    classId,
                    quantityStars: index + 1
                  }
                });
                setStarsState(index + 1);
                setClickAnimationTime(index + 1);
                setStarAnimationTime(index + 1);
                setTimeout(() => {
                  setClickAnimationTime(false);
                  setStarsText(index + 1);
                  setDivState("thanks");
                  setTimeout(() => {
                    setStarAnimationTime(false);
                  }, 600);
                }, 300);
              }}>
              <img
                src="/images/assets/star_empty.svg"
                className={`select-none absolute w-[18px] h-[18px] transition-600 ${
                  starsState <= index ? "opacity-100" : "opacity-100"
                }`}
                alt="Estrela Vazia"
              />
              <img
                src="/images/assets/star_filled.svg"
                className={`select-none absolute w-[18px] h-[18px] transition-600 ${
                  starsState > index
                    ? "opacity-100"
                    : starsHoverState > index
                    ? "opacity-100"
                    : "opacity-0"
                } ${
                  clickAnimationTime === index + 1 && "animate-clickAnimation"
                }`}
                alt="Estrela Cheia"
              />
            </div>
          ))}
        </div>
      </div>
      {/* Container de agradecimento */}
      <div
        className={`absolute flex items-center justify-center top-0 -ml-[20%] w-[140%] bg-backgroundTwo p-2 rounded-lg z-[11] pointer-events-none transition-600 ${
          divState === "thanks"
            ? "translate-y-[50px] delay-500"
            : "translate-y-[10px] opacity-0"
        }`}>
        <span className="text-xs leading-none">Obrigado!</span>
      </div>
    </div>
  );
}
