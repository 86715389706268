import axios from "axios";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/react";

export const newRecoveryRequest = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/newRecoveryPass`,
      { recovery: data }
    );

    return {
      data: jwtDecode(response.data.data),
      status: response.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};
