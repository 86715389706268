import React from "react";
import ProgressCard from "./ProgressCard/ProgressCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { certifier } from "../../mocks/certifier";

function CoursesInProgress({ courses,routeRedirect }) {
  return (
    <div className="w-full">
      <section className="flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <div className="flex w-2/3 flex-col gap-0.5 max-lg:w-full">
            <div className="font-bold text-[1.5rem] text-left leading-tight max-lg:text-[1.25rem]">
              Meus cursos
            </div>
          </div>
          {courses && (
            <Link className="flex w-fit min-w-[136.5px] justify-end max-lg:hidden" to={routeRedirect}>
             <ButtonPrimary
                // optionalBackground={certifier.colors.text.textPrimary}
                textButton="ver todos"
                desktopWidth="100%"
                mobileWidth="100%"
                outlineType
                outlineColor={certifier.colors.text.textPrimary}
                optionalTextColor={certifier.colors.text.textPrimary}
                optionalClass="hoverWhiteBg"
              />
            </Link>
          )}
        </div>
        <Swiper
          className="w-full"
          slidesPerView={1.5}
          slidesPerGroup={1}
          style={{
            marginRight: "0px",
            "--swiper-navigation-color": "#fff",
            "--swiper-navigation-size": "30px",
            "--swiper-navigation-top-offset": "0%",
            "--swiper-navigation-sides-offset": "0px",
          }}
          modules={[Navigation]}
          navigation={false}
          spaceBetween={20}
          speed={450}
          breakpoints={{
            1025: {
              navigation: true,
              slidesPerView: 3,
              slidesPerGroup: 3,
              style: { marginRight: "-12px" },
            },
          }}
        >
          {courses.map(
            (course, index) =>
              index < 3 &&
              course.progress < 100 && (
                <SwiperSlide key={index} className="w-full">
                  <ProgressCard
                    widthPercent={courses.length}
                    key={index}
                    image={course.image}
                    typeCourse={course.typeCourse}
                    name={course.name}
                    redirectTo={course.alias}
                    progress={course.progress}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </section>
    </div>
  );
}

export default CoursesInProgress;
