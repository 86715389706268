import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import BlockquoteModal from "./BlockquoteModal";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { certifier } from "../../mocks/certifier";
import { addNewCourse } from "../../services/studentService/student.service";
import { Toastify } from "../Toastify/Toastify";
import LoadingSection from "../LoadingElements/LoadingSection";

export default function RescueCourseModal({
  isOpen,
  onClose,
  courseName,
  courseAlias,
  typeCourse,
  studentData,
  getStudentData,
  setIsLoading,
  redirectUrl,
  undoTranslateTypeCourse
}) {
  const [localLoading, setLocalLoading] = useState(false);
  const containerRef = useRef(null);
  const modalRef = useRef(null);

  const handleConfirmRescue = async () => {
    try {
      if (!courseAlias || !typeCourse) {
        Toastify("error", "Selecione um curso para prosseguir");
        return;
      }
      if (setIsLoading) setIsLoading(true);
      else setLocalLoading(true);
      let student = studentData;
      if (!student && getStudentData) {
        student = await getStudentData();
      }
      if (!student) {
        Toastify("error", "Erro ao obter informações do estudante");
        if (setIsLoading) setIsLoading(false);
        else setLocalLoading(false);
        return;
      }

      const response = await addNewCourse(student, {
        courseAlias: courseAlias,
        typeCourse: undoTranslateTypeCourse
          ? undoTranslateTypeCourse(typeCourse)
          : typeCourse
      });

      if (response.status === 200) {
        handleOnClose();
        window.location.href = `${redirectUrl}?message=${encodeURIComponent(
          `${courseName} resgatado com sucesso!`
        )}`;
      } else {
        Toastify("error", "Erro ao resgatar o curso.");
      }
    } catch (error) {
      Toastify("error", "Erro ao resgatar o curso!");
    } finally {
      if (setIsLoading) setIsLoading(false);
      else setLocalLoading(false);
    }
  };

  const handleOnClose = () => {
    if (modalRef && modalRef.current)
      modalRef.current.style.animation = "fadeOutScale 0.3s ease forwards";
    if (containerRef && containerRef.current)
      containerRef.current.style.animation =
        "fadeOutOpacity 0.3s ease forwards";
    setTimeout(() => {
      if (onClose) onClose();
    }, 300);
  };

  if (isOpen)
    return ReactDOM.createPortal(
      // return (
      <>
        {localLoading && <LoadingSection isLoading={localLoading} />}
        <div
          ref={containerRef}
          style={{ animation: "fadeInOpacity 0.3s ease forwards" }}
          className={`fixed top-0 left-0 w-full h-full z-[200] flex items-center justify-center transition-300 text-textPrimary`}>
          <BlockquoteModal
            modalRef={modalRef}
            modalStyle={{ animation: "fadeInScale 0.3s ease forwards" }}
            text_top="Adesão ao curso"
            text_blockquote={`Você está resgatando o curso de <span class="font-semibold">${
              courseName || "..."
            }</span>, tem certeza que deseja continuar com essa ação?`}
            outsideClick={handleOnClose}
            buttonsRight={[
              <ButtonPrimary
                verifyOnClick={handleOnClose}
                disableHorizontalMovement
                optionalHoverBackground="#FFFFFF08"
                optionalBackground="#00000000"
                textButton="Cancelar"
                optionalTextColor={certifier.colors.text.textPrimary}
                desktopWidth="160px"
                mobileWidth="160px"
              />,
              <ButtonPrimary
                verifyOnClick={handleConfirmRescue}
                textButton="Confirmar"
                desktopWidth={150}
              />
            ]}
          />
        </div>
      </>,
      document.body
    );
}
