import React, { useEffect, useRef, useState } from "react";

const HomeLibanoVideoPlayer = ({
  autoPlay,
  className,
  src,
  time,
  backgroundVideo,
  style
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", adjustVideoDimensions);
    adjustVideoDimensions();
    return () => window.removeEventListener("resize", adjustVideoDimensions);
  }, [backgroundVideo]);

  const adjustVideoDimensions = () => {
    if (!containerRef.current) return;
    if (window.innerWidth <= 1024 && backgroundVideo) {
      const screenHeight = window.innerHeight;
      const desiredHeight = screenHeight * 0.85;
      const desiredWidth = desiredHeight * (16 / 9);
      containerRef.current.style.height = `${desiredHeight}px`;
      containerRef.current.style.width = `${desiredWidth}px`;
      containerRef.current.style.left = "50%";
      containerRef.current.style.transform = "translateX(-50%)";
    } else {
      const desiredHeight = window.innerWidth * (9 / 16);
      containerRef.current.style.width = `${window.innerWidth}px`;
      containerRef.current.style.height = `${desiredHeight}px`;
    }
  };

  return (
    <div
      ref={containerRef}
      style={style}
      id="video-player-container"
      className={className ?? "w-full h-full"}
    >
      <iframe
        allowFullScreen={"0"}
        className="rounded-[0.5rem] max-lg:rounded-none aspect-video h-fit"
        src={`${src}?start=${time ?? "0"}${
          autoPlay
            ? "&autoplay=true&mute=true&restartAfterEnd=true&hideControls=false"
            : ""
        }`}
        style={{
          border: "0",
          width: "100%"
        }}
      />
    </div>
  );
};

export default HomeLibanoVideoPlayer;
