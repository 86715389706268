import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toastify } from "../../components/Toastify/Toastify";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import SelectElement from "../../components/SelectElement/MainSelect";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { getListStudentCourses } from "../../services/courseService/course.service";
import { getAvaliationPage } from "../../services/avaliationServices/avaliation.service";
import "./avaliations.css";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import Cookies from "js-cookie";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";
import { updateInternalRateCourse } from "../../services/courseService/course.service";

function AvaliationsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [selectedCourseOption, setSelectedCourseOption] = useState({});
  const [avaliationData, setAvaliationData] = useState([]);
  const [avaliationModal, setAvaliationModal] = useState(undefined);
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);
  const [starsState, setStarsState] = useState(-1);
  const [starsHoverState, setStarsHoverState] = useState(-1);
  const [starAnimationTime, setStarAnimationTime] = useState(undefined);
  const location = useLocation();
  const rateCourseModalRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const percentageScore = searchParams.get("percentageScore");
  const [percentageScoreStored, setPercentageScoreStored] = useState(false);
  const [rateCourseModal, setRateCourseModal] = useState(
    percentageScoreStored && Number(percentageScoreStored) >= 300
  );
  const googleCertifierReviewUrl = "https://g.page/r/CRCNm7NcxU4yEAE/review";
  const googleMinimumStarsToRedirect = 5;
  useEffect(() => {
    if (percentageScore) {
      setRateCourseModal(
        percentageScore &&
          Number(percentageScore) >= 300 &&
          !Cookies.get("posAvaliationRateCourse")
      );
      setPercentageScoreStored(percentageScore);
      const url = new URL(window.location.href);
      url.searchParams.delete("percentageScore");
      window.history.replaceState({}, document.title, url.toString());
    }
  }, []);

  useEffect(() => {
    async function fetchStudentCourses() {
      setIsLoading(true);
      try {
        const student = await getStudentInfos();
        setStudentInfos(student);
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId
        });

        if (coursesResponse.status === 200) {
          const allCourses = coursesResponse.data
            .filter((course) => course.areaName !== "Softskills")
            .map((course) => ({
              label: course.courseName,
              value: course.courseAlias,
              courseId: course.courseId,
              matriculationId: course.matriculationId,
              typeCourse: course.typeCourse
            }));

          setCoursesOptions(allCourses);
          if (allCourses.length > 0) {
            const lastCourseAccessed = Cookies.get(
              "lastCourseAccessed_avaliation"
            );
            if (lastCourseAccessed) {
              const lastCourse = allCourses.find(
                (course) => course.value === lastCourseAccessed
              );
              if (lastCourse) {
                setSelectedCourseOption(lastCourse);
                await fetchAvaliations(lastCourse.value);
              } else {
                setSelectedCourseOption(allCourses[0]);
                await fetchAvaliations(allCourses[0].value);
              }
            } else {
              setSelectedCourseOption(allCourses[0]);
              await fetchAvaliations(allCourses[0].value);
            }
          }
        } else {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
      } finally {
        setIsLoading(false);
      }
    }

    fetchStudentCourses();
  }, []);

  const handleSelectCourseChange = async (selectedOption) => {
    setSelectedCourseOption(selectedOption);
    await fetchAvaliations(selectedOption.value);
    Cookies.set("lastCourseAccessed_avaliation", selectedOption.value, {
      expires: 1 / 24
    });
  };

  const disableRateCourseModal = () => {
    rateCourseModalRef.current.style.animation = "fadeOutOpacity 0.3s ease forwards";
    setTimeout(()=>{
      setRateCourseModal(false)
    },300)
  }

  async function fetchAvaliations(courseAlias) {
    setIsLoading(true);
    try {
      const studentInfos = await getStudentInfos();
      const avaliationResponse = await getAvaliationPage({
        studentInfos,
        courseAlias
      });
      if (avaliationResponse.status === 200) {
        setAvaliationData(
          avaliationResponse.data.map((item) => ({
            ...item,
            key: item.disciplineId
          }))
        );
      } else {
        Toastify("error", "Erro ao buscar informações das avaliações.");
      }
    } catch (error) {
      Toastify("error", "Erro ao processar as avaliações.");
    } finally {
      setIsLoading(false);
    }
  }

  const handleDirectAvaliation = async (item) => {
    const avaliationData = {
      sessionId: studentInfos.sessionId,
      avaliationId: item.avaliationId,
      isViewAvaliation: true
    };
    const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
    window.location.href = `/avaliation?key=${encodeURIComponent(
      String(cryptParam)
    )}`;
    setIsLoading(false);
  };

  const handleSelectAvaliation = (e) => {
    setAvaliationModal(e);
  };

  const handleStartAvaliation = async () => {
    try {
      setIsLoading(true);
      Cookies.set("lastCourseAccessed_avaliation", selectedCourseOption.value, {
        expires: 1 / 24
      });
      const avaliationData = {
        sessionId: studentInfos.sessionId,
        matriculationId: selectedCourseOption.matriculationId,
        courseId: selectedCourseOption.courseId,
        courseAlias: selectedCourseOption.value,
        disciplineId:
          avaliationModal.type === "avf" ? null : avaliationModal.disciplineId,
        avaliationType: avaliationModal.type
      };
      const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
      window.location.href = `/avaliation?key=${encodeURIComponent(
        String(cryptParam)
      )}`;
    } catch (error) {
      Toastify("error", "Erro ao iniciar avaliação.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen">
      <HeaderBar
        courseNavigation={false}
        actualPage="Provas"
        mobileMainText="Provas"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Provas" />
      <LoadingSection isLoading={isLoading} />
      <section
        style={{
          paddingBottom: windowWidth < 960 ? navBar : 0,
          paddingTop: windowWidth < 960 ? navBarHeader : 0
        }}
        className={`overflow-hidden mt-9 mb-9 flex ${
          windowWidth < 960 ? "h-[auto]" : ""
        } flex-col gap-5 w-full max-w-[1170px] bg-backgroundThree rounded-xl max-mb:mt-0 max-mb:mb-0 max-mc:rounded-none`}>
        {/* Cabeçalho */}
        <div className="p-10 flex justify-between min-[860px]:items-center max-[860px]:flex-col max-[860px]:gap-8 max-[860px]:p-6">
          <div className="flex flex-col w-full h-full">
            <div>
              <h1 className="my-2 text-3xl text-textPrimary font-bold leading-[1.35]">
                Avaliações Online
              </h1>
            </div>
            <span className="text-textSecondary text-sm">
              Realize as avaliações disponíveis e confira suas notas.
            </span>
          </div>
          {coursesOptions.length > 0 && (
            <div className="w-[60%] max-[860px]:w-full">
              <SelectElement
                options={coursesOptions}
                value={selectedCourseOption.value}
                onChange={handleSelectCourseChange}
              />
            </div>
          )}
        </div>
        {/* Avaliações */}
        <div className="w-full">
          {/* Header da tabela, substituindo gridTitles */}
          <div className="discipline-grid-avaliations px-10 max-mc:px-7 text-sm bg-backgroundTwo sticky shadow-md">
            <div className="py-4 font-semibold">Avaliações</div>
            <div className="text-center py-4 font-semibold max-md:hidden">
              Realização
            </div>
            <div className="text-center py-4 font-semibold">Nota</div>
            <div className="text-center py-4 font-semibold">Situação</div>
          </div>
          {/* Itens da tabela, agora utilizando avaliationData */}
          <div className="flex flex-col w-full">
            {avaliationData.map((item, index) => (
              <button
                onClick={() => {
                  if (item.blockAvaliation || item.dispense) {
                    return;
                  } else if (
                    (item.situation === "Reprovado" ||
                      item.situation === "Aprovado") &&
                    item.tries >= item.retries &&
                    !item.fake
                  ) {
                    handleDirectAvaliation(item);
                  } else if (
                    !item.fake ||
                    (item.fake && item.tries < item.retries)
                  ) {
                    handleSelectAvaliation(item);
                  }
                }}
                key={index}
                className={`text-center px-10 max-mc:px-7 discipline-grid-avaliations items-center bg-backgroundTwo ${
                  (item.blockAvaliation ||
                    item.dispense ||
                    (item.fake && item.tries >= item.retries)) &&
                  "cursor-default"
                } ${
                  item.blockAvaliation && !item.fake
                    ? "text-textSecondary"
                    : "hover:bg-backgroundThree"
                }`}>
                <div className="text-left py-5 truncate">
                  <p className="text-sm truncate">{item.disciplineName}</p>
                  {item.tries < item.retries &&
                    !item.dispense &&
                    item.situation !== "Disponível" &&
                    item.situation !== "Indisponível" && (
                      <p className="text-xs text-textSecondary truncate">
                        Você ainda tem {item.retries - item.tries} tentativa(s)
                      </p>
                    )}
                  {item.situation === "Indisponível" && item.dateUnlock && (
                    <p className="text-xs text-textSecondary truncate">
                      {item.dateUnlock}
                    </p>
                  )}
                </div>
                <div className="py-5 text-sm font-normal max-md:hidden">
                  {item.dateFinish}
                </div>
                <div className="py-5 text-sm font-normal">{item.grade}</div>
                <div className="py-5 text-sm font-normal">
                  <div className="flex w-full justify-center">
                    <span
                      className={` ${
                        item.situation === "Aprovado"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-[#3730a3] bg-[#e0e7ff]"
                          : ""
                      } ${
                        item.situation === "Reprovado"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-[#991b1b] bg-[#fee2e2]"
                          : ""
                      } ${
                        item.situation === "Em andamento" ||
                        item.situation === "Disponível"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-[#924049] bg-[#fef3c7]"
                          : ""
                      } ${
                        item.situation === "Indisponível"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-gray-500 bg-gray-100"
                          : ""
                      } `}>
                      {item.situation}
                    </span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </section>
      {/* Modal de Avaliacoes */}
      {avaliationModal && (
        <BlockquoteModal
          text_top="Realizar avaliação"
          text_blockquote={
            avaliationModal.situation !== "Em andamento"
              ? `A prova terá uma duração de ${String(
                  avaliationModal.duration / 60
                ).replace(".", ",")} hora${
                  avaliationModal.duration / 60 >= 2 ? "s" : ""
                }. Além disso, você terá ${String(
                  avaliationModal.retries - avaliationModal.tries
                )} tentativa${
                  avaliationModal.retries >= 2 ? "(s)" : ""
                } para realizar a avaliação. Está pronto para iniciar?`
              : "Você tem certeza de que deseja dar continuidade a esta avaliação?"
          }
          outsideClick={() => setAvaliationModal(undefined)}
          buttonsLeft={
            (avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
            !avaliationModal.fake
              ? [
                  <ButtonPrimary
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    textButton="Ver avaliação"
                    desktopWidth="160px"
                    mobileWidth="160px"
                    verifyOnClick={() =>
                      handleDirectAvaliation(avaliationModal)
                    }
                    disabled={studentInfos.userTeste}
                  />
                ]
              : []
          }
          buttonsRight={
            ((avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
              avaliationModal.tries < avaliationModal.retries) ||
            avaliationModal.situation === "Disponível" ||
            avaliationModal.situation === "Em andamento"
              ? [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Cancelar"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    desktopWidth="160px"
                    mobileWidth="160px"
                  />,
                  <ButtonPrimary
                    verifyOnClick={handleStartAvaliation}
                    textButton={`${
                      avaliationModal.situation === "Em andamento"
                        ? "Continuar"
                        : "Começar"
                    }`}
                    disabled={studentInfos.userTeste}
                  />
                ]
              : [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    optionalTextColor={certifier.colors.text.textPrimary}
                    disableHorizontalMovement
                    textButton="Voltar"
                    desktopWidth="160px"
                    mobileWidth="160px"
                  />
                ]
          }
        />
      )}
      {/* Modal de Avaliar Curso no Google */}
      {rateCourseModal && (
        <section ref={rateCourseModalRef} className={`fixed top-0 left-0 z-[199] transition-300`}>
          <BlockquoteModal
            blockquoteClass="max-w-[600px] max-md:max-w-[100%]"
            disable_blockquoteMode
            text_top="Parabéns!"
            text_blockquote={`<p class="w-full pb-[20px] text-center text-[1.5rem] font-bold">Nota da Avaliação: 10</p><p>Antes de continuar com seus estudos, gostaríamos que você compartilhasse com outras pessoas como está sendo sua experiencia de estudos.</p>`}
            innerObject={
              <div className="flex flex-col gap-y-5 items-center justify-center">
                <div className={`py-1 px-2 rounded-full`}>
                  <div className="flex h-full gap-x-5 items-center justify-center max-md:gap-x-3">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div className="transition-300 hover:scale-[1.15]">
                        <div
                          className={`${
                            starAnimationTime === index + 1 &&
                            "translate-y-[4px] translate-x-[1px] transition-300"
                          }`}>
                          <div
                            className={`origin-center w-[42px] h-[42px] cursor-pointer ${
                              starAnimationTime === index + 1 &&
                              "rotate-[144deg] transition-300"
                            }`}
                            onMouseLeave={() => setStarsHoverState(0)}
                            onMouseEnter={() => setStarsHoverState(index + 1)}
                            onClick={async () => {
                              setStarsState(index + 1);
                              setStarAnimationTime(index + 1);
                            }}>
                            <img
                              src="/images/assets/star_empty.svg"
                              className={`select-none absolute w-[42px] h-[42px] transition-600 ${
                                starsState <= index
                                  ? "opacity-100"
                                  : "opacity-100"
                              }`}
                            />
                            <img
                              src="/images/assets/star_filled.svg"
                              className={`select-none absolute w-[42px] h-[42px] transition-600 ${
                                starsState > index
                                  ? "opacity-100"
                                  : starsHoverState > index
                                  ? "opacity-100"
                                  : "opacity-0"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
            outsideClick={() => disableRateCourseModal()}
            buttonsRight={[
              <ButtonPrimary
                verifyOnClick={() => disableRateCourseModal()}
                disableHorizontalMovement
                optionalHoverBackground="#FFFFFF08"
                optionalBackground="#00000000"
                textButton="Cancelar"
                optionalTextColor={certifier.colors.text.textPrimary}
                desktopWidth="160px"
                mobileWidth="100%"
              />,
              <ButtonPrimary
                verifyOnClick={async () => {
                  try {
                    disableRateCourseModal();
                    await updateInternalRateCourse({
                      sessionId: studentInfos.sessionId,
                      content: {
                        courseId: selectedCourseOption.courseId,
                        courseAlias: selectedCourseOption.value,
                        quantityStars: starsState
                      }
                    });
                    if (starsState >= googleMinimumStarsToRedirect) {
                      window.open(googleCertifierReviewUrl, "_blank");
                    }
                    Cookies.set("posAvaliationRateCourse", starsState, {
                      expires: new Date(
                        new Date().getTime() + 120 * 24 * 60 * 60 * 1000
                      )
                    });
                    Toastify("success", "Avaliação enviada com sucesso!");
                  } catch (error) {
                    Toastify("error", "Erro ao enviar avaliação do curso!");
                    console.error(error);
                  }
                }}
                textButton="Adicionar Avaliação"
                disabled={starsState <= 0}
                desktopWidth="200px"
                mobileWidth="100%"
              />
            ]}
          />
        </section>
      )}
    </main>
  );
}

export default verifyAuth(AvaliationsPage);
