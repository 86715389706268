import React, { useEffect, useState } from "react";
import OriginalCoursesCard from "../OriginalCourses/OriginalCoursesCard/OriginalCoursesCard";

function undoTranslateTypeCourse(typeCourse) {
  const translatedTypeCourse = {
    "Pós-Graduação": "posGraduacao",
    "Cursos Livres": "cursosLivres"
  };
  return translatedTypeCourse[typeCourse] || typeCourse;
}

function translateTypeCourse(typeCourse) {
  const translatedTypeCourse = {
    posGraduacao: "Pós-Graduação",
    cursosLivres: "Cursos Livres"
  };
  return translatedTypeCourse[typeCourse] || typeCourse;
}

function ListCourses({
  enableEnterAllCourses,
  allCourses,
  headerTitle,
  onRenderAllCourses,
  isAvailableCourses
  // headerSubtitle,
}) {
  const [displayCourses, setDisplayCourses] = useState([]);
  const [quantity, setQuantity] = useState(8);
  useEffect(() => {
    if (allCourses) setDisplayCourses(allCourses.slice(0, 8));
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight
      )
        return;
      setQuantity((quantity) => {
        const newQuantity = quantity + 8;
        if (allCourses) setDisplayCourses(allCourses.slice(0, newQuantity));
        if (quantity + 8 >= allCourses.length && onRenderAllCourses)
          onRenderAllCourses(true);
        return newQuantity;
      });
    };
    const timer = setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 100);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [allCourses]);
  useEffect(() => {
    if (allCourses) setDisplayCourses(allCourses.slice(0, 8));
    setQuantity(8);
  }, [allCourses]);

  if (allCourses)
    return (
      <section className="w-full flex flex-col items-center rounded-md pt-8">
        {headerTitle && (
          <span
            className="font-bold text-[1.5rem] text-left max-w-[1170px] -ml-3 max-md:ml-12 w-full leading-tight max-lg:text-[1.25rem]"
            dangerouslySetInnerHTML={{ __html: headerTitle }}
          />
        )}
        <section
          id="courses-scroll"
          className={`max-w-[1170px] w-full flex flex-col items-center my-8 max-lg:my-3 ${
            headerTitle && "mt-0"
          }`}>
          <div className="w-full grid grid-cols-4 gap-[1.125rem] gap-y-5 max-lg:w-full max-lg:m-0 max-lg:grid-cols-2 max-lg:px-[5vw]">
            {typeof displayCourses === "object" &&
              displayCourses.map((course, index) => (
                <OriginalCoursesCard
                  courseId={course.courseId}
                  smallCard
                  key={index}
                  image={course.image}
                  typeCourse={translateTypeCourse(course.typeCourse)}
                  name={course.name}
                  teacher={course.teacher}
                  courseStars={
                    course.stars
                      ? course.stars.averageStars
                      : undefined
                  } //////////
                  redirectTo={
                    enableEnterAllCourses || course.unlockCourse
                      ? `/course/${course.alias}`
                      : isAvailableCourses &&
                        (isAvailableCourses ===
                          undoTranslateTypeCourse(course.typeCourse) ||
                          isAvailableCourses === "posGraduacao")
                      ? `/rescueCourse/${undoTranslateTypeCourse(
                          course.typeCourse
                        )}/${course.alias}`
                      : `/purchaseCourse/${undoTranslateTypeCourse(
                          course.typeCourse
                        )}/${course.alias}`
                  }
                />
              ))}
          </div>
        </section>
      </section>
    );
}

export default ListCourses;
