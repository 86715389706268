import axios from "axios";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";
import { CryptoService } from "../cryptoService/crypto.service";

export const uploadChunk = async ({
  sessionId,
  chunkData,
  chunkIndex,
  totalChunks,
  videoId,
  isPublic
}) => {
  try {
    const authToken = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );

    const formData = new FormData();
    formData.append("chunk", new Blob([chunkData]));
    formData.append("isPublic", isPublic);
    formData.append("chunkIndex", chunkIndex);
    formData.append("totalChunks", totalChunks);
    formData.append("videoId", videoId);

    const response = await axios.post(
      `${process.env.REACT_APP_API_VIDEO_ADDRESS}/upload-chunk`,
      formData,
      {
        headers: {
          auth: authToken,
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return response;
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    throw err;
  }
};

export const assembleChunks = async ({
  sessionId,
  videoId,
  totalChunks,
  originalFileName,
  isPublic
}) => {
  try {
    const authToken = await CryptoService(
      {
        sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );

    const assembleDataEncrypted = {
      videoId: videoId,
      totalChunks: totalChunks,
      originalFileName: originalFileName,
      isPublic: isPublic
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_VIDEO_ADDRESS}/completion-chunks`,
      assembleDataEncrypted,
      {
        headers: {
          auth: authToken,
          "Content-Type": "application/json"
        }
      }
    );

    return response;
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    throw err;
  }
};
