import { Link } from "react-router-dom";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

export default function NextSteps({ nextSteps }) {
  return (
    <section className="flex flex-col justify-center w-full gap-6">
      <span className="font-bold text-[1.5rem] text-left max-w-[1170px] w-full leading-tight max-lg:text-[1.25rem]">
        Próximos passos
      </span>
      <div className="flex justify-center items-center w-full h-full gap-12 bg-backgroundTwo px-[10%] my-[36px] rounded-[0.625rem] max-md:flex-col max-md:px-[36px] max-md:mb-0">
        <div
          className="flex aspect-square overflow-hidden max-w-[380px] my-[-36px]"
          style={{ boxShadow: "0 25px 77px -12px rgb(0 0 0 / 0.4" }}>
          <img
            src={nextSteps.image}
            alt={nextSteps.text}
            className="w-full h-full object-cover rounded-[0.875rem] pointer-events-none"
          />
        </div>
        <div className="flex flex-col gap-5 py-[3rem] w-[60%] max-md:w-full max-md:py-6 max-md:pb-[36px]">
          <p className="font-medium text-base select-none leading-tight line-clamp-3 max-lg:text-sm max-md:text-center">
            {nextSteps.description}
          </p>
          <span className="text-3xl text-textPrimary font-bold leading-[1.35] max-lg:leading-tight max-lg:text-2xl max-md:text-center md:line-clamp-3">
            {nextSteps.text}
          </span>
          <div className="flex w-full max-md:justify-center">
            <Link
              to={nextSteps.link}
              target="_self"
              className="flex w-fit max-md:justify-center">
              <ButtonPrimary
                textButton={nextSteps.button}
                desktopWidth="200px"
                mobileWidth="200px"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
