import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

export const putAdsStatus = async ({
  studentInfos,
  newStatus,
  campaignId,
  adId
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        type: newStatus,
        id: adId,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`
      }
    };

    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/updateRangeAdPortal`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }

    return {
      data: responseCrypt.data.data,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};
