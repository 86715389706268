import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { serverTime } from "../../services/serverTime/serverTime";
import moment from "moment";
import { certifier } from "../../mocks/certifier";
import { logoutRequest } from "../../services/logout/logout.service";
import * as Sentry from "@sentry/react";
import LoadingSection from "../../components/LoadingElements/LoadingSection";

const sign = require("jwt-encode");

function Logout() {
  useEffect(() => {
    async function logoutAction() {
      try {
        const studentInfo = await getStudentInfos();
        if (studentInfo) {
          const sessionId = studentInfo.sessionId;

          const timeServer = await serverTime();

          let iat = moment().unix();
          let exp = moment().add(2, "hours").unix();

          if (timeServer.status === 200) {
            const serverTimeISO = timeServer.data;
            iat = moment(serverTimeISO).unix();
            exp = iat + 2 * 3600;
          }

          const post_data = sign(
            {
              sessionId,
              certifierName: certifier.generalSettings.name,
              environment: process.env.REACT_APP_API_ENVIRONMENT,
              exp,
              iat
            },
            process.env.REACT_APP_JWT_KEY
          );
          logoutRequest(post_data)
            .then((res) => {
              if (res.status === 200) {
                Sentry.setUser(null);

                localStorage.removeItem("user");
                window.location.href = "/login";
                return;
              }
            })
            .catch((err) => {
              console.error("Erro ao processar a solicitação.");
              window.location.href = "/login?reason=invalidLogout";
            });
        } else {
          throw new Error("No user session found");
        }
      } catch (error) {
        window.location.href = "/login?reason=noSessionLogout";
      }
    }
    logoutAction();
  }, []);
  return (
    <section>
      <LoadingSection isLoading hideScroll />
    </section>
  );
}

export default Logout;
