import "./styles/App.css";
import "./styles/WhiteLabel.css";
import "./styles/colors.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomePage from "./pages/homeRedirect/homeRedirect";
import Login from "./pages/login/login";
import Logout from "./pages/logout/logout.jsx";
import RecoveryPass from "./pages/recoveryPass/recoveryPass";
import NewRecovery from "./pages/newRecovery/newRecovery";
import Dashboard from "./pages/dashboard/dashboard";
import Course from "./pages/course/course";
import StudentCourses from "./pages/studentCourses/studentCourses";
import DeclarationsPage from "./pages/declarations/declarations";
import ContractPage from "./pages/contract/contract";
import ClassPage from "./pages/classPage/classPage";
import TitleAndSubtitle from "./components/TitleAndSubtitle/TitleAndSubtitle";
import Page404 from "./pages/Page404/Page404";
import AvaliationsPage from "./pages/avaliations/avaliations";
import CertificatePage from "./pages/certificate/certificate";
import DocumentsPage from "./pages/documents/documents";
import TCCPage from "./pages/tcc/tcc";
import BasicInfosPage from "./pages/basicInfos/basicInfos";
import SpecifiedAreaPage from "./pages/specifiedAreaPage/specifiedAreaPage";
import FinancePage from "./pages/finance/finance";
import InsideAvaliation from "./pages/insideAvaliation/insideAvaliation";
import WhatsappRedirect from "./pages/whatsappRedirect/whatsappRedirect";
import ExternalLoginRedirect from "./pages/externalLoginRedirect/externalLoginRedirect";
import { Analytics } from "@vercel/analytics/react";
import AllAvailableCoursesPage from "./pages/allAvailableCoursesPage/allAvailableCoursesPage";
import RateCoursePage from "./pages/rateCoursePage/rateCoursePage";
import SearchAreaPage from "./pages/searchAreaPage/searchAreaPage";
import NewCourse from "./pages/newCourse/newCourse";
// import { ErrorBoundary } from "./pages/Page500/ErrorBoundary";

function App() {
  return (
    <main className="App">
      <Analytics />
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <TitleAndSubtitle title="Redirecionando..." /> <HomePage />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <TitleAndSubtitle title="Entrar" /> <Login />
              </>
            }
          />
          <Route
            path="/logout"
            element={
              <>
                <TitleAndSubtitle title="Saindo..." /> <Logout />
              </>
            }
          />
          <Route
            path="/recoveryPass"
            element={
              <>
                <TitleAndSubtitle title="Recuperação de Senha" />
                <RecoveryPass />
              </>
            }
          />
          <Route
            path="/newRecovery"
            element={
              <>
                <TitleAndSubtitle title="Nova Senha" />
                <NewRecovery />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <TitleAndSubtitle title="Início" />
                <Dashboard />
              </>
            }
          />
          <Route
            path="/course/:courseAlias"
            element={
              <>
                <TitleAndSubtitle title="Curso" />
                <Course />
              </>
            }
          />
          <Route
            path="/availableCourses"
            element={
              <>
                <TitleAndSubtitle title="Cursos Disponíveis" />
                <AllAvailableCoursesPage />
              </>
            }
          />
          <Route
            path="/purchaseCourse/:typeCourse/:courseAlias"
            element={
              <>
                <TitleAndSubtitle title="Adquira este curso" />
                <NewCourse purchaseType />
              </>
            }
          />
          <Route
            path="/rescueCourse/:typeCourse/:courseAlias"
            element={
              <>
                <TitleAndSubtitle title="Resgate este curso" />
                <NewCourse />
              </>
            }
          />
          <Route
            path="/studentCourses"
            element={
              <>
                <TitleAndSubtitle title="Meus Cursos" />
                <StudentCourses />
              </>
            }
          />
          <Route
            path="/searchArea"
            element={
              <>
                <TitleAndSubtitle title="Cursos Específicos da Área" />
                <SearchAreaPage />
              </>
            }
          />
          <Route
            path="/recommendedCourses"
            element={
              <>
                <TitleAndSubtitle title="Cursos Recomendados" />
                <SpecifiedAreaPage recommendedCourses={true} />
              </>
            }
          />
          <Route
            path="/course/:courseAlias/:disciplineId/:classId"
            element={
              <>
                <TitleAndSubtitle title="Aula" />
                <ClassPage />
              </>
            }
          />
          <Route
            path="/demoCourse/:typeCourse/:courseAlias"
            element={
              <>
                <TitleAndSubtitle title="Demonstração de Curso" />
                <ClassPage demoCourse />
              </>
            }
          />
          <Route
            path="/user/declarations"
            element={
              <>
                <TitleAndSubtitle title="Declarações" />
                <DeclarationsPage />
              </>
            }
          />
          <Route
            path="/user/contracts"
            element={
              <>
                <TitleAndSubtitle title="Contrato" />
                <ContractPage />
              </>
            }
          />
          <Route
            path="/user/certificates"
            element={
              <>
                <TitleAndSubtitle title="Certificado" />
                <CertificatePage />
              </>
            }
          />
          <Route
            path="/user/documents"
            element={
              <>
                <TitleAndSubtitle title="Documentos" />
                <DocumentsPage />
              </>
            }
          />
          <Route
            path="/tcc"
            element={
              <>
                <TitleAndSubtitle title="Trabalho de Conclusão de Curso" />
                <TCCPage />
              </>
            }
          />
          <Route
            path="/rateCourse/:courseAlias"
            element={
              <>
                <TitleAndSubtitle title="Avaliação do Curso" />
                <RateCoursePage />
              </>
            }
          />
          <Route
            path="/user/basicInfos"
            element={
              <>
                <TitleAndSubtitle title="Informações Básicas" />
                <BasicInfosPage />
              </>
            }
          />
          <Route
            path="/avaliation"
            element={
              <>
                <TitleAndSubtitle title="Avaliação" />
                <InsideAvaliation />
              </>
            }
          />
          <Route
            path="/avaliations"
            element={
              <>
                <TitleAndSubtitle title="Avaliações Online" />
                <AvaliationsPage />
              </>
            }
          />
          <Route
            path="/finance"
            element={
              <>
                <TitleAndSubtitle title="Finanças" />
                <FinancePage />
              </>
            }
          />
          <Route path="/support" element={<WhatsappRedirect />} />
          <Route
            path="*"
            element={
              <>
                <TitleAndSubtitle title="Página Não Encontrada." />
                <Page404 />
              </>
            }
          />
          <Route
            path="/externalLogin/:firstAccessKey"
            element={
              <>
                <TitleAndSubtitle title="Redirecionando...." />
                <ExternalLoginRedirect />
              </>
            }
          />
        </Routes>
      </Router>
    </main>
  );
}

export default App;
