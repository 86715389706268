import React, { useEffect, useRef } from "react";
import { postPlayerMoment } from "../../services/playerService/player.service";

const PandaVideoPlayer = ({
  autoPlay,
  sessionId,
  disciplineId,
  classId,
  courseId,
  courseAlias,
  className,
  rateCourseBox,
  src,
  time,
  backgroundVideo,
  style
}) => {
  let player;

  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.pandavideo.com.br/api.v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      let videoStatus = "";
      window.pandascripttag = window.pandascripttag || [];
      window.pandascripttag.push(function () {
        player = new window.PandaPlayer(`panda-${src.split("v=")[1]}`, {
          onReady: () => {
            setInterval(async () => {
              if (
                !backgroundVideo &&
                sessionId &&
                classId &&
                courseId &&
                disciplineId &&
                videoStatus === "playing"
              ) {
                postPlayerMoment({
                  sessionId: sessionId,
                  watchedSeconds: Math.floor(await player.getCurrentTime()),
                  totalSeconds: Math.floor(await player.getDuration()),
                  disciplineId: disciplineId,
                  classId: classId,
                  courseId: courseId
                });
              }
            }, 5000);

            player.onEvent(function ({ message }) {
              try {
                if (message === "panda_pause") {
                  videoStatus = "paused";
                } else if (message === "panda_play") {
                  videoStatus = "playing";
                } else if (message === "panda_enterfullscreen") {
                  if (containerRef.current.requestFullscreen) {
                    containerRef.current.requestFullscreen();
                  } else if (containerRef.current.mozRequestFullScreen) {
                    containerRef.current.mozRequestFullScreen();
                  } else if (containerRef.current.webkitRequestFullscreen) {
                    containerRef.current.webkitRequestFullscreen();
                  } else if (containerRef.current.msRequestFullscreen) {
                    containerRef.current.msRequestFullscreen();
                  }
                } else if (message === "panda_exitfullscreen") {
                  if (document.exitFullscreen) {
                    document.exitFullscreen();
                  } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                  } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                  } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                  }

                }
                // } else if (message === "panda_ended") {
                //   if (rateCourseBox)
                //     window.location.href = `/rateCourse/${courseAlias}`;
              } catch (error) {
                console.error(`Player Event Error - ${error.message}`);
              }
            });
          },
          onError: (event) => {
            console.error("Player onError", event);
          }
        });
      });
    };

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [backgroundVideo, classId, courseId, disciplineId, sessionId, src]);

  useEffect(() => {
    const handleResize = () => {
      try {
        const containerWidth = containerRef.current.offsetWidth;
        const newHeight = (9 / 16) * containerWidth;
        containerRef.current.style.height = `${newHeight + 3}px`;
      } catch (error) {
        console.error(`Player Resize Error - ${error.message}`);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      try {
        if (window.screen.orientation.type.startsWith("landscape")) {
          if (containerRef.current.requestFullscreen) {
            containerRef.current.requestFullscreen();
          } else if (containerRef.current.mozRequestFullScreen) {
            containerRef.current.mozRequestFullScreen();
          } else if (containerRef.current.webkitRequestFullscreen) {
            containerRef.current.webkitRequestFullscreen();
          } else if (containerRef.current.msRequestFullscreen) {
            containerRef.current.msRequestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      } catch (error) {
        console.error(`Player Orientation Error - ${error.message}`);
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={style}
      id="video-player-container"
      className={className ?? "w-full h-full mt-[-2px]"}>
      <iframe
        id={`panda-${src.split("v=")[1]}`}
        src={`${src}&preload=true&startTime=${
          time ?? "0"
        }&troubleshootDialog=false${
          autoPlay
            ? "&autoplay=true&muted=true&hideControlsOnStart=true&hideControls=true&restartAfterEnd=true&initDisabledControls=true"
            : ""
        }`}
        className="aspect-video"
        style={{
          border: "0",
          width: "100%",
          height: "100%"
        }}
      />
    </div>
  );
};

export default PandaVideoPlayer;
